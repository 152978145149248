.searchbar {
  margin-bottom: 20px;

  input {
    padding: 10px 15px;
    min-width: 215px;
    height: 40px;
    width: 100%;
    font-size: 16px;
    font-weight: 300;
    color: var(--color-dark);
    border-radius: 30px;
    border: none;
    background-color: rgba(0, 0, 0, 0.07);
    transition: all 0.3s ease-in-out;

    &:placeholder {
      color: var(--color-light-gray);
    }

    &:focus {
      box-shadow: 0px 0px 7px 0px rgba(var(--color-primary-rgb), 1);
      background-color: rgba(0, 0, 0, 0.03);
    }
  }

  .Search-icon,
  .Clear-icon {
    position: relative;
    top: -30px;
    float: right;
    opacity: 0.65;
    font-size: 1.2rem;
    margin-right: 15px;
  }
}
