.Icon {
  display: inline-block;
  mask-size: contain !important;
  mask-position: center !important;
  mask-repeat: no-repeat !important;

  &.Arrow-circled {
    mask: url("../../../../components/ui/icons/arrow-circled.svg");
  }

  &.Arrow-right {
    mask: url("../../../../components/ui/icons/arrow.svg");
  }

  &.Arrow-left {
    mask: url("../../../../components/ui/icons/arrow.svg");
    transform: rotate(180deg);
  }

  &.Calendar {
    mask: url("../../../../components/ui/icons/calendar.svg");
  }

  &.Chevron-down {
    mask: url("../../../../components/ui/icons/arrow-lg-blk.svg");
  }

  &.Chevron-left {
    mask: url("../../../../components/ui/icons/arrow-lg-gr-270.svg");
  }

  &.Chevron-right {
    mask: url("../../../../components/ui/icons/arrow-lg-gr-270.svg");
    transform: scaleX(-1);
  }

  &.Circled-checkmark {
    mask: url("../../../../components/ui/icons/circled-checkmark.svg");
  }

  &.Circled-plus {
    mask: url("../../../../components/ui/icons/circled-plus.svg");
  }

  &.Copy {
    mask: url("../../../../components/ui/icons/copy.svg");
  }

  &.Checkbox-circular-empty {
    mask: url("../../../../components/ui/icons/checkbox-circular-empty.svg");
  }

  &.Checkbox-circular-filled {
    mask: url("../../../../components/ui/icons/checkbox-circular-filled.svg");
  }

  &.Checkbox-empty-admin {
    mask: url("../../../../components/ui/icons/checkbox-empty-admin.svg");
  }

  &.Checkbox-filled-admin {
    mask: url("../../../../components/ui/icons/checkbox-filled-admin.svg");
  }

  &.Checkbox-empty {
    mask: url("../../../../components/ui/icons/checkbox-empty.svg");
  }

  &.Checkbox-filled {
    mask: url("../../../../components/ui/icons/checkbox-filled.svg");
  }

  &.Checkbox-indeterminate {
    mask: url("../../../../components/ui/icons/checkbox-indeterminate.svg");
  }

  &.Checkmark {
    mask: url("../../../../components/ui/icons/checkmark.svg");
  }

  &.Csv {
    mask: url("../../../../components/ui/icons/csv.svg");
  }

  &.Device-assigned {
    mask: url("../../../../components/ui/icons/device-assigned.svg");
  }

  &.Device-unassigned {
    mask: url("../../../../components/ui/icons/device-unassigned.svg");
  }

  &.Device {
    mask: url("../../../../components/ui/icons/device.svg");
  }

  &.Download {
    mask: url("../../../../components/ui/icons/download.svg");
  }

  &.Dot {
    mask: url("../../../../components/ui/icons/green-dot.svg");
  }

  &.Edit {
    mask: url("../../../../components/ui/icons/edit.svg");
  }

  &.Edit-admin {
    mask: url("../../../../components/ui/icons/edit-admin.svg");
  }

  &.Ellipsis {
    mask: url("../../../../components/ui/icons/ellipsis.svg");
  }

  &.Info {
    mask: url("../../../../components/ui/icons/info.svg");
  }

  &.Jobs {
    mask: url("../../../../components/ui/icons/jobs.svg");
  }

  &.New-worker {
    mask: url("../../../../components/ui/icons/new-worker.svg");
  }

  &.New-tab {
    mask: url("../../../../components/ui/icons/new-tab.svg");
  }

  &.Search {
    mask: url("../../../../components/ui/icons/search\ symbol.svg");
    transform: scaleX(-1);
  }

  &.Sign-out {
    mask: url("../../../../components/ui/icons/sign-out.svg");
  }

  &.Skyline {
    mask: url("../../../../components/ui/icons/skyline.svg");
  }

  &.Sort-none,
  &.Sort-desc,
  &.Sort-asc {
    mask: url("../../../../components/ui/icons/sort-none.svg");
  }

  &.Delete-trash {
    mask: url("../../../../components/ui/icons/trash-can.svg");
  }

  &.Upload {
    mask: url("../../../../components/ui/icons/upload.svg");
  }

  &.Worker {
    mask: url("../../../../components/ui/icons/Worker\ Img.svg");
  }

  &.X-mark {
    mask: url("../../../../components/ui/icons/x-mark.svg");
  }

  &.Cog {
    mask: url("../../../../components/ui/icons/gear.svg");
  }

  &.Cog-solid {
    mask: url("../../../../components/ui/icons/gear-solid.svg");
  }
}
