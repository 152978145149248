/******* Typography *******/
h2 {
  color: #333;
  font-size: 24px;
  font-weight: 500;
}

h3 {
  color: #333;
  font-size: 14px;
}

/******* Flex ********/
.flex-row {
  display: flex;
  flex-flow: row;
}

.flex-right {
  margin-left: auto;
}

.flex-row.justify-evenly {
  justify-content: space-evenly;
}

.flex-2-column {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.flex-2-column.wide {
  margin: 120px 0;
  width: 100%;
  min-height: 90vh;
}

.flex-2-column.wide .main-column {
  flex: 3;
}

.flex-2-column .main-column {
  flex: 2;
  padding: 40px 0 0 0;
}

.flex-2-column.wide .main-column {
  flex: 3;
}

.flex-2-column .sidebar {
  flex: 1;
  padding: 40px 0 0 0;
}

.flex-2-column .sidebar h4 {
  color: #333;
  border-bottom: 1px solid #333;
  margin: 10px 0;
}

/****** Buttons ******/
.button {
  color: #fff;
  display: inline-block;
  background-color: #4d66fc;
  font-size: 14px;
  padding: 14px 30px 12px;
  text-align: center;
  width: auto;
  transition: all 0.1s linear;
  user-select: none;
  border-radius: 2px;
}

.button.secondary {
  color: #4d66fc;
  background-color: #fff;
  border: 1px solid #4d66fc;
}

.button.tertiary {
  background-color: #e9e9e9;
  padding: 10px 15px;
  border-radius: 0;
}

.button:hover {
  opacity: 0.6;
  cursor: pointer;
}

.button.secondary:hover {
  background-color: #d8d8d8;
}

.button.tertiary:hover {
  background-color: #e5e5e5;
}

.button.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.button.disabled.secondary {
  background: #fff;
  border-color: #bababa;
  color: #bababa;
}

.button.disabled:hover {
  cursor: default;
}

.button.disabled.secondary:hover {
  cursor: default;
  background-color: #fff;
}

.button.warn {
  background-color: #ff6b6b;
}

.button.warn:hover {
  background-color: #ff9e9e;
}

.button.large {
  font-size: 17px;
}

.button.xlarge {
  font-size: 24px;
}

/****** Header ******/
.header {
  color: #333333;
  font-weight: 500;
  font-size: 32px;
}

/****** Forms ******/

input {
  padding: 8px;
  min-width: 215px;
}

.error {
  color: #f00;
  font-size: 14px;
}

form.stacked-labels label {
  color: #333;
  font-size: 14px;
  display: block;
  margin: 25px 0 10px 0;
}

form.stacked-labels .select-box-container {
  display: block;
}

form .button-group {
  display: flex;
  align-items: center;
  margin: 40px 0 0 0;
}

form .button-group div {
  margin-right: 15px;
}

/****** Modals **********/
.modal-wrapper {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1200;
  display: flex;
  flex-flow: row;
  opacity: 0;
  pointer-events: none;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.75);
  transition: opacity 250ms;
  -webkit-box-pack: center;
  -webkit-box-align: center;
}

@media only screen and (max-height: 640px) {
  .modal-wrapper {
    position: absolute;
    height: 120vh;
  }
}

.modal-wrapper.shown {
  opacity: 1;
  pointer-events: initial;
}

.ui-modal {
  background: #fff;
  display: none;
  width: 620px;
  box-shadow: 0 0 1px rgba(100, 100, 100, 0.5);
  z-index: 1;
}

.shown .ui-modal {
  display: initial;
}

.ui-modal .modal-title-bar {
  background: none;
  border-bottom: 1px solid #bababa;
  color: #333;
  font-size: 24px;
  padding: 38px 0px 35px 0px;
  display: block;
  width: 540px;
  margin: 0 auto;
  position: relative;
}

.ui-modal .modal-title-bar.solid {
  border: none;
  background: #e9e9e9;
  color: #868686;
  font-size: 20px;
  width: auto;
  padding: 18px 40px 38px 40px;
  text-align: center;
}

.ui-modal .modal-body {
  padding: 25px 40px 0 40px;
}

.ui-modal .modal-body h4 {
  color: #333;
  margin: 20px 0 0 0;
}

.ui-modal .modal-body ul {
  padding: 0px 0 0 25px;
  list-style: disc;
}

.ui-modal .modal-body p,
.ui-modal .modal-body li {
  font-size: 14px;
  line-height: 17px;
  margin: 14px 0;
}

.ui-modal .modal-body .button-group {
  margin-top: 85px;
  text-align: right;
  justify-content: space-between;
  padding-bottom: 28px;
}

.ui-modal .modal-body .button-group .button {
  margin-left: 30px;
}

.ui-modal .modal-body .button-group .text-link {
  margin-left: 30px;
}

.ui-modal .modal-body img {
  width: 100%;
  height: auto;
}

.ui-modal .back-button {
  position: absolute;
  top: 19px;
  left: 15px;
}

.ui-modal .back-button img {
  width: 30px;
  height: auto;
}

.ui-modal .close-button {
  cursor: pointer;
  position: absolute;
  top: 19px;
  right: 20px;
}

/****** Progress Bar *******/
.progress-bar {
  box-shadow: none;
  display: block;
  background: #e9e9e9;
  height: 14px;
  margin-bottom: 10px;
  position: relative;
  width: 100%;
}

.progress-bar .progress-fill {
  background: #405fce;
  position: absolute;
  height: 14px;
  top: 0;
  left: 0;
  width: 0;
  transition: width 0.4s linear;
}

/****** Select Box ******/
.select-box-container {
  align-items: center;
  display: flex;
  flex-flow: row;
  z-index: 10;
  position: relative;
}

.select-box-container .hover-message {
  display: hidden;
  position: absolute;
  bottom: -30px;
  background: #fff;
  border: 1px solid #333;
  color: #333;
  width: 100%;
  padding: 5px 5px;
  opacity: 0;
  transition: 200ms opacity ease-in;
}

.select-box-container:hover .hover-message {
  opacity: 1;
}

.typeahead.active,
.select-box-container.active {
  position: relative;
  z-index: 1000;
}

.select-box-container label {
  color: #333;
  font-size: 14px;
  padding: 0 10px 0 0;
}

.typeahead {
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
}

.typeahead .display {
  display: block;
  border: 1px solid #d8d8d8;
  border-radius: 2px;
  padding: 10px 8px;
  color: #333;
  font-size: 14px;
  width: 100%;
}

.typeahead.active input {
  display: block;
}

.typeahead.active .display {
  display: none;
}

.typeahead,
.select-box {
  position: relative;
  z-index: 10;
}

.typeahead input {
  background: #fff;
  border: 1px solid #d8d8d8;
  border-radius: 2px;
  color: #333;
  font-size: 14px;
  width: 100%;
}

.typeahead.disabled {
  background: 1px solid #999;
  color: #999;
}

.select-box .picker {
  border: 1px solid #d8d8d8;
  border-radius: 2px;
  color: #333;
  font-size: 14px;
  padding: 10px 45px 10px 20px;
  position: relative;
}

.select-box .picker.disabled {
  color: #999;
}

.select-box .picker.arrow {
  background: #e9e9e9;
  border-radius: 0;
  border: none;
  padding: 11px 10px;
}

.select-box .picker.color {
  background-color: #405fce;
  color: #fff;
}

.select-box .picker.color.disabled {
  background-color: #bababa;
}

.select-box .picker.ellipsis {
  border: none;
  padding: 15px 0;
}

.select-box .picker.ellipsis img {
  display: block;
  margin: 0 auto;
}

.select-box .picker.ellipsis img:hover {
  cursor: pointer;
}

.select-box .picker.chip {
  background-color: #d8d8d8;
  border-radius: 20px;
  max-width: 180px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.select-box .picker.chip.some {
  background-color: #e3e9fe;
  padding-left: 30px;
}

.select-box .picker .arrow-icon {
  position: absolute;
  right: 13px;
  top: 13px;
  max-width: 14px;
  background: black;
}

.typeahead .choices,
.select-box .choices {
  background: #fff;
  border: 1px solid #e2e2e2;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.21);
  display: none;
  min-width: 190px;
  max-height: 30vh;
  overflow: auto;
  padding: 10px 4px;
  position: absolute;
  top: 35px;
}

.flex-right .select-box .choices {
  right: 0;
}

.typeahead .choices.active,
.select-box .choices.active {
  display: block;
  z-index: 1010;
}

.typeahead .choices .choice,
.select-box .choices .choice {
  color: #333;
  font-size: 14px;
  padding: 10px 8px;
  transition: all 0.2s ease-out;
  display: flex;
  align-items: center;
}

.typeahead .choices .choice.chosen,
.select-box .choices .choice.chosen {
  color: #405fce;
  background-color: #daeef2;
}

.select-box .choices.multi .choice.chosen {
  background-color: transparent;
}

.typeahead .choices .choice:hover,
.select-box .choices .choice:hover {
  background-color: #daeef2;
  border-radius: 2px;
}

.select-box .choices .choices-heading {
  color: #333;
  font-weight: bold;
  padding: 10px 10px 10px 10px;
}

.select-box .choices .choices-heading .close {
  opacity: 1;
}

.select-box .clearer {
  position: absolute;
  top: 10px;
  left: 30px;
  z-index: 1020;
  opacity: 0.6;
  transition: opacity 0.2s ease-in;
}

.select-box .clearer:hover {
  cursor: pointer;
  opacity: 1;
}

/****** Text Links ******/
.text-link {
  display: inline-block;
  color: #405fce;
}

.text-link.secondary {
  color: #868686;
  border-bottom: 1px solid #898989;
}

.text-link:hover {
  cursor: pointer;
  color: #6990d8;
}

.block-link {
  border: 2px solid #d8d8d8;
  font-family: MikroMedium;
  text-align: center;
  color: #898989;
  font-size: 18px;
  padding: 15px;
  position: relative;
}

.block-link .edit {
  position: absolute;
  top: 15px;
  right: 15px;
  left: initial;
}

.text-link.disabled:hover {
  cursor: default;
}

.vertical-line {
  width: 1px;
  border-left: 1px solid #e9e9e9;
}

.warning {
  background: #ffff9e;
  padding: 15px 20px;
  display: block;
  margin: 20px 0;
}

.success-msg {
  background: #9eff9e;
  padding: 15px 20px;
  display: block;
  margin: 20px 0;
}
