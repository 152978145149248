.setup-terms {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  min-height: 50vh;
  margin: 50px 0;
}

.setup-terms p {
  color: #333;
  font-size: 28px;
  text-align: center;
  line-height: 1.75;
  max-width: 800px;
  margin: 0 auto;
}

.setup-terms p a {
  color: #405fce;
  text-decoration: underline;
}

.setup-terms-disagreed {
  color: #ef3737;
  font-size: 20px;
  line-height: 1;
}

.setup-terms .button-group {
  display: flex;
  text-align: center;
  justify-content: space-around;
}
