#location-detail-page {
  .DayPicker {
    box-shadow: rgba(0, 0, 0, 0.09) 0px 1px 4px, rgba(0, 0, 0, 0.09) 0px 1px 4px;
  }
  .date-picker-container {
    position: relative;
    top: -20px;
  }

  .flex-inherit {
    flex-basis: inherit;
  }

  .flex-container {
    display: flex;
  }

  .fa-calendar-alt {
    z-index: 100;
    font-size: 20px;
    position: absolute;
    left: 10px;
    top: 45px;
    color: gray;
  }

  .icon-input {
    padding-left: 40px;
  }

  .date-picker {
    position: absolute;
    background: white;
    z-index: 1000;

    .DayPicker-Day--selected {
      background: blue;
      background-color: rgb(46, 46, 46) !important;
      color: white !important;
      border-radius: 0px !important;
    }
  }
}
