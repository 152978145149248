.ui-checkbox {
  border: none;
  height: 24px;
  width: 24px;
  margin: 10px 15px 10px 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #bbbdc9;
  border-radius: 2px;

  .Icon {
    width: 100%;
    height: 100%;
    background-color: var(--color-primary, #4d66fc);
    mask-size: 160% !important;

    &.checkbox {
      &-filled {
        mask: url("../../../../components/ui/icons/checkbox-filled-admin.svg");
      }

      &-empty {
        mask: url("../../../../components/ui/icons/checkbox-empty-admin.svg");
        background-color: transparent !important;
      }

      &-indeterminate {
        mask: url("../../../../components/ui/icons/checkbox-indeterminate.svg");
      }
    }
  }

  &:hover {
    cursor: pointer;
  }
}

.ui-checkbox.checkbox-filled {
  border: 2px solid #4d66fc;
}

.ui-checkbox.checkbox-indeterminate {
  border: 2px solid #4d66fc;
}
