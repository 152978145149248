.Dropdown-select-container {
  .dropdown-select {
    border-radius: 3px;
    border: 1px solid var(--color-light-gray);
    padding: 8px;
    color: var(--color-grey-text);
    height: 40px;
    padding: 5px 25px 5px 20px;
    -webkit-appearance: none;
    width: inherit;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
      background-color: rgba(0, 0, 0, 0.15) !important;
    }
  }

  &.round {
    .dropdown-select {
      border: none;
      background: var(--color-background);
      border-radius: 100px;
    }

    .Chevron-down {
      background-color: var(--color-grey-text) !important;
    }
  }

  .input-inner {
    display: flex;
    align-items: center;
    width: 100%;

    .Chevron-down {
      background-color: var(--color-secondary);
      pointer-events: none;
      margin-left: -20%;
    }

    &.disabled {
      pointer-events: none;
    }
  }
}
