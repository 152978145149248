.login-container {
  display: flex;
  font-size: 20px;
  margin: auto;
  min-height: 100vh;
  width: 640px;

  form {
    margin: auto;
  }

  h1 {
    font-size: 45px;
    margin-bottom: 30px;
  }

  .heading {
    font-weight: bold;
    margin-bottom: 30px;
  }

  .error-msg {
    color: #a44;
    font-size: 16px;
    margin-bottom: 2em;
  }

  input {
    color: #000;
    border: 2px solid #ccc;
    border-radius: 10px;
    display: block;
    margin-bottom: 15px;
    min-width: 100%;
    padding: 12px 18px;

    &[type="submit"] {
      background: #5e7dfd;
      border: 0 none;
      border-radius: 6px;
      color: white;
      display: block;
      font-weight: bold;
      font-size: 16px;
      min-width: 140px;
      padding: 15px 0;
      text-align: center;

      &[disabled] {
        opacity: 0.75;
      }
    }
  }

  a {
    color: #6482b4;
    display: block;
    font-size: 16px;
    margin: 20px 0 25px 0;
    text-decoration: underline;
  }

  .saml-redirect-link {
    font-size: 16px;
    font-style: italic;
    color: #333;
    margin: 20px 0 25px 0;

    a {
      display: inline;
      font-size: 16px;
    }
  }

  .new-to-kinetic {
    font-size: 14px;
    color: #333;
    margin-top: 3vh;

    a {
      display: inline;
      font-size: 14px;
    }
  }
}
