.forgot-password {
  display: flex;
  min-height: 100vh;

  form {
    border: 1px solid #ddd;
    border-radius: 6px;
    box-shadow: 0 0 20px #ddd;
    font-size: 20px;
    margin: auto;
    padding: 40px;
    width: 480px;
  }

  .heading {
    font-weight: bold;
    color: #333;
  }

  .instructions {
    font-size: 14px;
    color: #333;
    margin: 10px 0 25px 0;
  }

  .error-msg {
    color: #a44;
    font-size: 16px;
    margin-bottom: 2em;
  }

  .success-msg {
    color: #333;
    font-size: 14px;
    line-height: 1.5;
    margin: 10px 0 25px 0;

    b {
      font-weight: bold;
    }
  }

  input {
    border: 2px solid #ccc;
    border-radius: 10px;
    display: block;
    margin-bottom: 15px;
    min-width: 100%;
    padding: 12px 18px;

    &[type="submit"] {
      background: #5e7dfd;
      border: 0 none;
      border-radius: 6px;
      color: white;
      display: block;
      font-weight: bold;
      font-size: 16px;
      margin: 2em 0 0 0;
      min-width: 140px;
      padding: 15px 0;
      text-align: center;

      &[disabled] {
        opacity: 0.75;
      }
    }
  }

  a {
    color: #5e7dfd;
    font-size: 16px;
  }
}
