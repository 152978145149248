.new-tooltip {
  display: flex;
  float: left;

  .tooltip-icon {
    padding-left: 5px;
    float: left;
    position: relative;
    svg {
      circle {
        fill: #777;
      }
      text {
        font: italic 1em serif;
        fill: white;
      }
    }
  }
}

.tooltip-icon .tooltiptext {
  visibility: hidden;
  background-color: #777;
  color: #fff;
  text-align: center;
  padding: 5px 5px;
  border-radius: 6px;

  position: absolute;
  z-index: 1;
  width:125px;
  top: -54px;
  left: -51px;
}

.tooltip-icon:hover .tooltiptext {
  visibility: visible;
}
